<template>
  <div>
    <ContentFilter @submit="handleSubmit" @reset="handleReset" />
    <Card
      :body-style="{ border: 0, marginTop: '20px' }"
      shadow="never"
    >
      <template v-slot:header>{{ $t('6ae7656') }}</template>
      <div v-loading="loading">
        <DisplayCard
          v-for="item in data"
          :key="item.id"
          :data="item"
          @delete="handleDelete"
        >
          <template v-slot:footer>
            <span class="action-button" @click="handleViewClick(item.id)">
              <span
                style="margin-right: 6px; vertical-align: middle"
              >{{ $t('696c3e0') }}</span>
              <Icon :src="viewImage" size="13" />
            </span>
          </template>
        </DisplayCard>
        <Empty v-if="!(data && data.length)" type="requestCoop" />
      </div>
      <Pagination :page-info="pageInfo" @changePage="changePage" />
    </Card>
  </div>
</template>

<script>
import "./index.less"
import Pagination from "@/components/Pagination"
import Card from "@/components/Card"
import Icon from "@/components/Icon"
import Empty from "@/components/Empty/Factory"

import sendImage from "@/assets/content/send.png"
import viewImage from "@/assets/content/view.png"

import ContentFilter from "./filter/index"
import DisplayCard from "./displayCard/index"
import api from "@/api/request"

export default {
  name: "RequestCooperation",
  components: {
    ContentFilter,
    DisplayCard,
    Pagination,
    Card,
    Icon,
    Empty,
  },
  props: {},
  data() {
    return {
      viewImage,
      sendImage,
      data: [],
      loading: false,
      pageInfo: {
        currentPage: 1,
        size: 8,
        total: 0,
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    jumpAdd() {
      this.$router.push("/content/request/release")
    },
    handleSubmit(values) {
      const newValues = { ...values }
      delete newValues.date
      if (values.date) {
        newValues.startTime = values.date[0]
          .toLocaleDateString()
          .replace("/", "-")
          .replace("/", "-")
        newValues.endTime = values.date[1]
          .toLocaleDateString()
          .replace("/", "-")
          .replace("/", "-")
      }
      this.getList(newValues)
    },
    handleReset() {
      this.getList()
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getList()
    },
    handleDelete(id) {
      api.delete(id).then((res) => {
        this.getList()
      })
    },
    handleViewClick(id) {
      this.$router.push({
        path: `/content/request/cooperation_detail?id=${id}`,
        query: { id: id },
      })
    },

    getList(params = {}) {
      this.loading = true
      api
        .getServiceList({
          size: this.pageInfo.size,
          page: this.pageInfo.currentPage,
          ...params,
        })
        .then((res) => {
          this.loading = false
          if (res) {
            this.data = res.content
            this.pageInfo.total = res.page.total
          }
        })
    },
  },
}
</script>
