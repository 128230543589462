<template>
  <Empty
    :image="config.image"
    :text="$t(config.text)"
    :button-text="config.buttonText"
    :button-icon="config.buttonIcon"
    @click="$emit('click')"
  />
</template>

<script>
import Empty from './index'
import contentEmptyImage from '@/assets/common/empty.png'
import lookupEmptyImage from '@/assets/common/lookupEmpty.png'
import consultEmptyImage from '@/assets/common/consultEmpty.png'

const metaData = {
  request: {
    image: contentEmptyImage,
    text: 'cd99735'
  },
  requestCoop: {
    image: contentEmptyImage,
    text: '6281cee',
  },
  service: {
    image: contentEmptyImage,
    text: '171985b'
  },
  serviceCoop: {
    image: contentEmptyImage,
    text: 'ede4a7d'
  },
  serviceContent: {
    image: contentEmptyImage,
    text: '暂时没有相关内容'
  },
  lookup: {
    image: lookupEmptyImage,
    text: '34e525e'
  },
  consult: {
    image: consultEmptyImage,
    text: 'dbf250c'
  }
}

export default {
  name: "Factory",
  components: {
    Empty,
  },
  props: {
    type: {
      type: String,
      default: undefined
    },
  },
  computed: {
    config() {
      return metaData[this.type] || {}
    }
  }
}
</script>

<style scoped>

</style>
