<template>
  <div class="empty">
    <img :src="image" alt="" />
    <p>{{ text }}</p>
    <el-button
      v-if="buttonText"
      size="small"
      style="
        height: 32px;
        padding: 8px 22px;
        border-color: rgba(0, 164, 255, 1);
        margin-top: 20px;
      "
      @click="$emit('click')"
    >
      <Icon :src="buttonIcon" size="12" />
      <span
        style="
          margin-left: 10px;
          color: rgba(0, 164, 255, 1);
          font-size: 14px;
        "
      >
        {{ buttonText }}
      </span>
    </el-button>
  </div>
</template>

<script>
import emptyImage from '@/assets/common/empty.png'
import Icon from '@/components/Icon'

export default {
  name: 'Empty',
  components: {
    Icon,
  },
  props: {
    image: {
      type: String,
      default: emptyImage
    },
    text: {
      type: String,
      default: '暂无数据'
    },
    buttonText: {
      type: String,
      default: undefined,
    },
    buttonIcon: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      emptyImage
    }
  }
}
</script>

<style scoped lang="less">
.empty {
  padding: 30px;
  color: #999999;
  font-size: 14px;
  text-align: center;
  img {
    display: inline-block;
    width: 250px;
    height: 258px;
  }
  p {
    margin-top: 30px;
  }
}
</style>
